import React, { useContext, useState, useEffect } from 'react';
/*External packages
    Material UI: styles/component library, 
    Formik: form components/ form methods,
    Yup: form validation 
*/
//Material UI
import {
  Card,
  CardContent,
  Box,
  InputAdornment,
  Typography,
} from '@material-ui/core';
import { spacing } from '@material-ui/system';

//Formik
import { Form, Formik, FormikConfig, FormikValues } from 'formik';
//Yup
import { object, mixed, number, date, string } from 'yup';
import * as Yup from 'yup';
//Custom Components
import RainSteps from './RainSteps';
import LandingPage from './LandingPage';
import InjectForm from './InjectForm';
import TrueFalseRadio from './smallAndReusable/TrueFalseRadio';
import MaterialUIInput from './smallAndReusable/MaterialUIInput';
import MaterialUIInputPercent from './smallAndReusable/MaterialUIInputPercent';
import DropDown from './smallAndReusable/DropDown';
import MaterialUIDatePicker from './smallAndReusable/MaterialUIDatePicker';
import EvidenceTree from './EvidenceTree';
//Custom hooks contexts etc.
import { StepContext } from './Contexts/StepContext';
import { ApiCallContext } from './Contexts/ApiCallContext';
import { InputContext } from './Contexts/UserInputContext';
import { PDFContext } from './Contexts/PDFContext';
import CanAddDropDown from './smallAndReusable/CanAddDropDown';

function RainForm(props) {
  //pull out methods from apiContext

  const { step } = useContext(StepContext);

  const {
    injectionStep,
    runResponse,
    resRelationship,
    outcomeFact,
    setOutcomeFact,
    evidence,
    setEvidence,
  } = useContext(ApiCallContext);

  const {
    yesNo,
    setYesNo,
    dropDown,
    setDropDown,
    standardInput,
    setMenuItem,
    inputDate,
    canAddValue,
    questions,
    outcome,
  } = useContext(InputContext);

  const { handlePDFData, setPDFObj } = useContext(PDFContext);

  /**
   * TODO: handle this with setState and useEffect if possible
   */

  let knownAnswers = [];

  const handleKnownAnswersLoop = (concept) => {
    if (knownAnswers.includes(concept.value)) {
      return;
    } else {
      knownAnswers.push(concept.value);
    }
  };

  //? Extra array here because CanAdd component requires menuItems prop as an array of objects.

  let canAddAnswers = [];

  const handleCanAddAnswers = (concept) => {
    if (canAddAnswers.includes(concept.value)) {
      return;
    } else {
      canAddAnswers.push({ title: concept.value });
    }
  };

  /**
   *
   * TODO: Abstract this logic to it's own component or APICALLCONTEXT
   *
   */

  //logic to handle different question types in response object and update the PDFData object

  const handleSubmit = async (values) => {
    console.log(step);
    console.log(outcome);
    //clear all dataTypes except standard which is handled at APICallContext line 87
    setDropDown('');
    setYesNo('');
    //running injection to RB after the intial form
    if (step === 2) {
      await injectionStep(values);
      handlePDFData(values);
    } else if (step === 3) {
      //reset dropDown on rerender
      setDropDown('');
      setMenuItem('');
      if (outcome !== undefined) {
        return;
      }
      //Standard Input
      else if (
        questions[0].dataType === 'string' &&
        questions[0].concepts === undefined
      ) {
        handlePDFData(standardInput, questions[0]);
        const responseData = {
          answers: [
            {
              subject: questions[0].subject,
              relationship: resRelationship,
              object: standardInput,
              cf: '100',
            },
          ],
        };

        await runResponse(responseData);
      }
      //TODO: First form questions know the subject and object and are looking for an answer prop of yes or no.
      // wording = "First Form"
      /**
       * TODO: "answers": [
      {
       "subject": "Dave",
       "object": "Indirect Revenue - Prospect Identification ",
       "relationship": "rainbird believes they are using pattern",
       "cf": 100,
       "answer": "yes"
      }
      ]
       */
      //? Second form object answer should be obj
      //? second form subject answer should be the subject
      //Handle the dropdown
      else if (
        questions[0].dataType === 'string' &&
        questions[0].concepts !== undefined &&
        questions[0].canAdd === false
      ) {
        handlePDFData(dropDown, questions[0]);
        const responseData = {
          answers: [
            {
              subject: questions[0].subject,
              relationship: resRelationship,
              object: dropDown,
              cf: '100',
            },
          ],
        };
        await runResponse(responseData);
      }
      //Handle the CanAddDropDown
      else if (
        questions[0].dataType === 'string' &&
        questions[0].concepts !== undefined &&
        questions[0].canAdd === true
      ) {
        handlePDFData(canAddValue.title, questions[0]);
        const responseData = {
          answers: [
            {
              subject: questions[0].subject,
              relationship: resRelationship,
              object: canAddValue.title,
              cf: '100',
            },
          ],
        };
        await runResponse(responseData);
      }
      //Handle Yes/No checkboxes
      else if (questions[0].dataType === 'truth') {
        //TODO: handlePDFData(null, questions[0]);
        // converting string to bool for RB
        let boolForRB;

        if (yesNo === 'true') {
          boolForRB = true;
        } else if (yesNo === 'false') {
          boolForRB = false;
        } else {
          console.error('Radio Boolean Error');
        }

        const responseData = {
          answers: [
            {
              subject: questions[0].subject,
              relationship: resRelationship,
              object: boolForRB,
              cf: '100',
            },
          ],
        };
        await runResponse(responseData);
      }
      //Handle standard input with number
      else if (questions[0].dataType === 'number') {
        handlePDFData(standardInput, questions[0]);
        //convert string to int for RB
        let IntForRB = parseInt(standardInput, 10);
        const responseData = {
          answers: [
            {
              subject: questions[0].subject,
              relationship: resRelationship,
              object: IntForRB,
              cf: '100',
            },
          ],
        };
        await runResponse(responseData);
      }
      //handle Dates
      else if (questions[0].dataType === 'date') {
        handlePDFData(inputDate, questions[0]);
        const responseData = {
          answers: [
            {
              subject: questions[0].subject,
              relationship: resRelationship,
              object: inputDate,
              cf: '100',
            },
          ],
        };
        await runResponse(responseData);
      }
    }
  };

  return (
    <CardContent>
      <FormikStepper
        initialValues={{
          Name: '',
          CN: '',
          CorpTaxRef: '',
          AddressLine1: '',
          AddressLine2: '',
          AddressLine3: '',
          PostCode: '',
          Date: new Date(),
          Email: '',
          terms: false,
        }}
        //VALIDATION//
        /*
        validationSchema={object({
          Name: string().required(),
          CN: number().required(),
          Address: string().required(),
          Date: date().required(),
          Email: string().required().email('You need an email'),
          terms: string().required(),
        })}
        */
        onSubmit={(values, formikHelpers) => {
          return new Promise((res) => {
            res();
            handleSubmit(values);
          });
        }}
      >
        <React.Fragment>
          <LandingPage />
        </React.Fragment>
        <React.Fragment>
          <InjectForm />
        </React.Fragment>
        <React.Fragment>
          {(() => {
            if (questions !== undefined) {
              return (
                <React.Fragment>
                  {questions.map((question, index) => {
                    if (question.dataType === 'truth') {
                      return (
                        <TrueFalseRadio
                          question={question.prompt}
                          key={index}
                        />
                      );
                    } else if (question.concepts && question.canAdd === false) {
                      return (
                        <React.Fragment key={index}>
                          {question.concepts.map((concept) =>
                            handleKnownAnswersLoop(concept)
                          )}
                          <Box paddingBottom={2}>
                            <DropDown
                              label="Select One"
                              question={question.prompt}
                              menuItems={knownAnswers}
                              key={index}
                            />
                          </Box>
                        </React.Fragment>
                      );
                    } else if (question.concepts && question.canAdd === true) {
                      return (
                        <React.Fragment key={index}>
                          {question.concepts.map((concept) =>
                            handleKnownAnswersLoop(concept)
                          )}
                          <Box paddingBottom={2}>
                            <CanAddDropDown
                              label="Select or Add an Answer"
                              question={question.prompt}
                              menuItems={canAddAnswers}
                              key={index}
                            />
                          </Box>
                        </React.Fragment>
                      );
                    } else if (question.dataType === 'date') {
                      return (
                        <React.Fragment key={index}>
                          <Box paddingBottom={2}>
                            <MaterialUIDatePicker
                              name={question.prompt}
                              id={question.id}
                              label={question.prompt}
                              type="date"
                            />
                          </Box>
                        </React.Fragment>
                      );
                    } else if (
                      question.relationship ===
                        'gross assets prior to investment' ||
                      question.relationship ===
                        'gross assets after investment' ||
                      question.relationship ===
                        'application is for investment' ||
                      question.relationship === 'maximum investment per year'
                    ) {
                      return (
                        <React.Fragment key={index}>
                          <MaterialUIInput
                            name={question.prompt}
                            id={question.id}
                            label={question.prompt}
                            type="number"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  £
                                </InputAdornment>
                              ),
                              max: 999999999999999,
                              min: 0,
                            }}
                          />
                        </React.Fragment>
                      );
                    } else if (
                      question.relationship ===
                        'company owns subsidiary shares' ||
                      question.relationship ===
                        'parent company holds company shares'
                    ) {
                      return (
                        <React.Fragment key={index}>
                          <MaterialUIInputPercent
                            id={question.id}
                            label={question.prompt}
                            type="number"
                            InputProps={{
                              inputProps: {
                                max: 999999999999999,
                                min: 0,
                              },
                            }}
                          />
                        </React.Fragment>
                      );
                    } else if (
                      question.relationship === 'investor holds shares'
                    ) {
                      let questionArr = question.prompt.split('?');

                      return (
                        <React.Fragment key={index}>
                          <MaterialUIInputPercent
                            name={questionArr[1]}
                            id={question.id}
                            label={`${questionArr[0]}?`}
                            type="number"
                            InputProps={{
                              inputProps: {
                                max: 999999999999999,
                                min: 0,
                              },
                            }}
                          />
                        </React.Fragment>
                      );
                    } else if (question.dataType === 'number') {
                      return (
                        <React.Fragment key={index}>
                          <Box paddingBottom={2}>
                            <MaterialUIInput
                              name={question.prompt}
                              id={question.id}
                              label={question.prompt}
                              type="number"
                              InputProps={{
                                inputProps: {
                                  max: 999999999999999,
                                  min: 0,
                                },
                              }}
                            />
                          </Box>
                        </React.Fragment>
                      );
                    } else {
                      return (
                        <React.Fragment key={index}>
                          <MaterialUIInput
                            name={question.prompt}
                            id={question.id}
                            label={question.prompt}
                            type="input"
                          />
                        </React.Fragment>
                      );
                    }
                  })}
                </React.Fragment>
              );
            } else {
              return null;
            }
          })()}
        </React.Fragment>
        <React.Fragment>
          {(() => {
            if (questions !== undefined) {
              return (
                <React.Fragment>
                  {questions.map((question, index) => {
                    if (question.dataType === 'truth') {
                      return (
                        <TrueFalseRadio
                          question={question.prompt}
                          key={index}
                        />
                      );
                    } else if (question.concepts && question.canAdd === false) {
                      return (
                        <React.Fragment key={index}>
                          {question.concepts.map((concept) =>
                            handleKnownAnswersLoop(concept)
                          )}
                          <Box paddingBottom={2}>
                            <DropDown
                              label="Select One"
                              question={question.prompt}
                              menuItems={knownAnswers}
                              key={index}
                            />
                          </Box>
                        </React.Fragment>
                      );
                    } else if (question.concepts && question.canAdd === true) {
                      return (
                        <React.Fragment key={index}>
                          {question.concepts.map((concept) =>
                            handleCanAddAnswers(concept)
                          )}
                          <Box paddingBottom={2}>
                            <CanAddDropDown
                              label="Select or Add an Answer"
                              question={question.prompt}
                              menuItems={canAddAnswers}
                              key={index}
                            />
                          </Box>
                        </React.Fragment>
                      );
                    } else if (question.dataType === 'date') {
                      return (
                        <React.Fragment key={index}>
                          <Box paddingBottom={2}>
                            <MaterialUIDatePicker
                              name={question.prompt}
                              id={question.id}
                              label={question.prompt}
                              type="date"
                            />
                          </Box>
                        </React.Fragment>
                      );
                    } else if (
                      question.relationship ===
                        'gross assets prior to investment' ||
                      question.relationship ===
                        'gross assets after investment' ||
                      question.relationship === 'maximum investment per year' ||
                      question.relationship === 'application is for investment'
                    ) {
                      return (
                        <React.Fragment key={index}>
                          <MaterialUIInput
                            name={question.prompt}
                            id={question.id}
                            label={question.prompt}
                            type="number"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  £
                                </InputAdornment>
                              ),
                              max: 999999999999999,
                              min: 0,
                            }}
                          />
                        </React.Fragment>
                      );
                    } else if (
                      question.relationship ===
                        'company owns subsidiary shares' ||
                      question.relationship ===
                        'parent company holds company shares'
                    ) {
                      return (
                        <React.Fragment key={index}>
                          <MaterialUIInputPercent
                            id={question.id}
                            label={question.prompt}
                            type="number"
                            InputProps={{
                              inputProps: {
                                max: 999999999999999,
                                min: 0,
                              },
                            }}
                          />
                        </React.Fragment>
                      );
                    } else if (
                      question.relationship === 'investor holds shares'
                    ) {
                      let questionArr = question.prompt.split('?');

                      return (
                        <React.Fragment key={index}>
                          <MaterialUIInputPercent
                            name={questionArr[1]}
                            id={question.id}
                            label={`${questionArr[0]}?`}
                            type="number"
                            InputProps={{
                              inputProps: {
                                max: 999999999999999,
                                min: 0,
                              },
                            }}
                          />
                        </React.Fragment>
                      );
                    } else if (
                      question.relationship ===
                      'application is time since oi/cst'
                    ) {
                      console.log('annoying question');
                      return (
                        <React.Fragment key={index}>
                          <Box paddingBottom={2}>
                            <MaterialUIInput
                              name={question.prompt}
                              id={question.id}
                              label={question.prompt}
                              questionLength="long"
                              type="number"
                              InputProps={{
                                inputProps: {
                                  max: 999999999999999,
                                  min: 0,
                                },
                              }}
                            />
                          </Box>
                        </React.Fragment>
                      );
                    } else if (question.dataType === 'number') {
                      return (
                        <React.Fragment key={index}>
                          <Box paddingBottom={2}>
                            <MaterialUIInput
                              name={question.prompt}
                              id={question.id}
                              label={question.prompt}
                              type="number"
                              InputProps={{
                                inputProps: {
                                  max: 999999999999999,
                                  min: 0,
                                },
                              }}
                            />
                          </Box>
                        </React.Fragment>
                      );
                    } else {
                      return (
                        <React.Fragment key={index}>
                          <MaterialUIInput
                            name={question.prompt}
                            id={question.id}
                            label={question.prompt}
                            type="input"
                          />
                        </React.Fragment>
                      );
                    }
                  })}
                </React.Fragment>
              );
            } else {
              return null;
            }
          })()}
        </React.Fragment>
        <React.Fragment>
          {(() => {
            if (outcome !== undefined) {
              if (outcome === 'SEIS') {
                return (
                  <React.Fragment>
                    <Box paddingBottom={2} style={{ fontWeight: 'bold' }}>
                      SEIS / EIS / EIS KIC
                    </Box>
                    <Box paddingBottom={2}>
                      Based on the information you have provided it is likely
                      that you are eligible for the following schemes:
                    </Box>
                    <Box paddingBottom={2} style={{ fontWeight: 'bold' }}>
                      SEIS, EIS and EIS Knowledge Intensive.
                    </Box>
                    <Box paddingBottom={2}>
                      The company’s investment history will dictate which scheme
                      it will need to apply to in the current funding round.
                    </Box>
                    <Box paddingBottom={2} style={{ fontWeight: 'bold' }}>
                      SEIS
                    </Box>
                    <Box paddingBottom={2}>
                      If you have not previously raised funding with a venture
                      capital scheme, it may be possible to raise some or all of
                      the current proposed round under the SEIS scheme.
                    </Box>
                    <Box paddingBottom={2}>
                      If you are applying for the SEIS scheme, please use the
                      link to HMRC’s form which needs to be completed and
                      submitted to HMRC.
                    </Box>
                    <Box paddingBottom={2} style={{ fontWeight: 'bold' }}>
                      EIS and EIS Knowledge Intensive
                    </Box>
                    <Box paddingBottom={2}>
                      If you are applying to the EIS or EIS Knowledge Intensive
                      schemes, please download the partially completed PDF
                      (populated based on your responses). Please review the
                      form carefully and enter the remaining required
                      information.
                    </Box>
                    <Box paddingBottom={2}>
                      There are additional supporting documentation requirements
                      in relation to the EIS Knowledge Intensive Scheme that
                      need to be prepared before submission to HMRC.
                    </Box>
                    <Box paddingBottom={4}>
                      Please reach out to [contact name] who will be happy to
                      assist in collating all the required documentation.
                    </Box>
                  </React.Fragment>
                );
              } else if (outcome === 'EIS') {
                return (
                  <React.Fragment>
                    <Box paddingBottom={2} style={{ fontWeight: 'bold' }}>
                      EIS / EIS KIC
                    </Box>
                    <Box paddingBottom={2}>
                      Based on the information you have provided it is likely
                      that you are eligible for the following schemes:
                    </Box>
                    <Box paddingBottom={2} style={{ fontWeight: 'bold' }}>
                      Enterprise Investment Scheme (EIS), or EIS Knowledge
                      Intensive scheme.
                    </Box>
                    <Box paddingBottom={2}>
                      The company’s investment history will dictate which scheme
                      it will need to apply to in the current funding round.
                    </Box>
                    <Box paddingBottom={2} style={{ fontWeight: 'bold' }}>
                      EIS and EIS Knowledge Intensive
                    </Box>
                    <Box paddingBottom={2}>
                      If you are applying to the EIS or EIS Knowledge Intensive
                      schemes, please download the partially completed PDF
                      (populated based on your responses). Please review the
                      form carefully and enter the remaining required
                      information.
                    </Box>
                    <Box paddingBottom={2}>
                      There are additional supporting documentation requirements
                      in relation to the EIS Knowledge Intensive Scheme that
                      need to be prepared before submission to HMRC.
                    </Box>
                    <Box paddingBottom={4}>
                      Please reach out to [contact name] who will be happy to
                      assist in collating all the required documentation.
                    </Box>
                  </React.Fragment>
                );
              } else if (outcome === 'EIS Knowledge Intensive') {
                //TODO: remove this outcome if irrelevant
                return (
                  <React.Fragment>
                    <Box paddingBottom={2} style={{ fontWeight: 'bold' }}>
                      EIS KIC
                    </Box>
                    <Box paddingBottom={2}>
                      Based on the responses you have provided; you may be
                      eligible for the EIS Knowledge Intensive scheme.
                    </Box>
                    <Box paddingBottom={2}>
                      The company’s investment history and capital deployment
                      strategy will dictate which scheme it will need to apply
                      to in the current funding round, along with the company's
                      ability to demonstrate certain additional criteria is met
                      in relation to the EIS Knowledge intensive scheme.
                    </Box>
                    <Box paddingBottom={2}>EIS Knowledge Intensive Scheme</Box>
                    <Box paddingBottom={2}>
                      If you are applying to the EIS Knowledge Intensive scheme,
                      please download the partially completed PDF (populated
                      based on your responses). Please review the form carefully
                      and enter the remaining required information.
                    </Box>
                    <Box paddingBottom={2} style={{ fontWeight: 'bold' }}>
                      There are additional supporting documentation requirements
                      in relation to the EIS Knowledge Intensive Scheme that
                      need to be prepared before submission to HMRC.
                    </Box>
                    <Box paddingBottom={4}>
                      Please reach out to [contact name] who will be happy to
                      assist in collating all the required documentation.
                    </Box>
                  </React.Fragment>
                );
              } else if (outcome === 'None') {
                return (
                  <React.Fragment>
                    <p>
                      Based on the responses you have provided, it is unlikely
                      that you would be eligible for the venture capital
                      schemes, specifically SEIS, EIS or EIS Knowledge
                      Intensive. This is due to the following reasons:{' '}
                    </p>
                    <EvidenceTree factID={outcomeFact} />
                  </React.Fragment>
                );
              } else if (outcome === true) {
                return (
                  <p>
                    Based on the information you have provided it is likely that
                    you are eligible.
                  </p>
                );
              } else if (outcome === false) {
                return (
                  <React.Fragment>
                    <p>
                      {' '}
                      Based on the responses you have provided, it is unlikely
                      that you would be eligible for the venture capital
                      schemes, specifically SEIS, EIS or EIS Knowledge
                      Intensive. This is due to the following reasons:
                    </p>
                    <EvidenceTree factID={outcomeFact} />
                  </React.Fragment>
                );
              } else {
                return <p>Error</p>;
              }
            }
          })()}
        </React.Fragment>
      </FormikStepper>
    </CardContent>
  );
}

export default RainForm;

//adding multi page functionality
export function FormikStepper({
  children,
  ...props
}: FormikConfig<FormikValues>) {
  //break children of the form into an array
  const childrenArray = React.Children.toArray(children);

  //pull step from context
  const { step } = useContext(StepContext);

  //render the current step
  const currentChild = childrenArray[step];

  //return formik component with relevant form state
  return (
    <Formik {...props}>
      <Form>
        {currentChild}
        <RainSteps />
      </Form>
    </Formik>
  );
}
