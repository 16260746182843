import React, { useContext } from 'react';
import { InputContext } from '../Contexts/UserInputContext';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Box,
} from '@material-ui/core';

const TrueFalseRadio = (props) => {
  const { question } = props;

  const { yesNo, setYesNo } = useContext(InputContext);

  const handleChange = (ev) => {
    setYesNo(ev.target.value);
    /**
     * TODO: Record data for PDF in a hash table
     */
  };

  return (
    <Box paddingBottom={4}>
      <FormControl component="fieldset" name="method-of-payment">
        <p>{question}</p>
        <RadioGroup
          onChange={(e) => {
            handleChange(e);
          }}
          value={yesNo}
        >
          <FormControlLabel
            value="true"
            control={<Radio color="primary" />}
            label="Yes"
          />
          <FormControlLabel
            value="false"
            control={<Radio color="primary" />}
            label="No"
          />
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

export default TrueFalseRadio;
