import React from 'react';

export default function RainTitle(props) {
  const title = props.title;
  return (
    <React.Fragment>
      <h1>{title}</h1>
    </React.Fragment>
  );
}
