/* eslint-disable no-use-before-define */
import React, { useContext } from 'react';
import { TextField, InputLabel, Box, makeStyles } from '@material-ui/core';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import { InputContext } from '../Contexts/UserInputContext';

const filter = createFilterOptions();

export default function CanAddDropDown(props) {
  const { label, question, menuItems } = props;
  const { canAddValue, setCanAddValue } = useContext(InputContext);

  return (
    <Autocomplete
      value={canAddValue}
      onChange={(event, newValue) => {
        if (typeof newValue === 'string') {
          setCanAddValue({
            title: newValue,
          });
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          setCanAddValue({
            title: newValue.inputValue,
          });
        } else {
          setCanAddValue(newValue);
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        // Suggest the creation of a new value
        if (params.inputValue !== '') {
          filtered.push({
            inputValue: params.inputValue,
            title: `Add "${params.inputValue}"`,
          });
        }

        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id="free-solo-with-text-demo"
      options={menuItems}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.title;
      }}
      renderOption={(option) => option.title}
      style={{ width: 600 }}
      freeSolo
      renderInput={(params) => (
        <React.Fragment>
          <Box paddingBottom={4}>
            <InputLabel id="demo-controlled-open-select-label">
              {question}
            </InputLabel>
          </Box>
          <TextField {...params} label={label} color="primary" />
        </React.Fragment>
      )}
    />
  );
}
