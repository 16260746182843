import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import '../components/ComponentCSS/EvidenceTree.css';

const baseURL = 'https://api.rainbird.ai/analysis/evidence/';

function EvidenceTree({ factID }) {
  const [data, setData] = useState(undefined);

  useEffect(() => {
    fetch(baseURL + factID)
      .then((response) => response.json())
      .then((data) => console.log(data) || setData(data));
  }, [factID]);

  console.log(data);

  return (
    <Box paddingBottom={1} className="EvidenceTree">
      {data && (
        <React.Fragment>
          <React.Fragment>
            {data.rule !== undefined && (
              <React.Fragment>
                {data.rule.alt && (
                  <p style={{ margin: 0, marginLeft: 15 }}>{data.rule.alt}</p>
                )}
              </React.Fragment>
            )}
          </React.Fragment>

          {data.source === 'rule' && (
            <React.Fragment>
              {data.rule.conditions.map((c) => (
                <EvidenceTree factID={c.factID} />
              ))}
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </Box>
  );
}

export default EvidenceTree;
