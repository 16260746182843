import React, { useContext } from 'react';
//Custom exports
import { StepContext } from './Contexts/StepContext';
import { ApiCallContext } from './Contexts/ApiCallContext';
import { InputContext } from './Contexts/UserInputContext';
import { PDFContext } from './Contexts/PDFContext';
import { usePDF } from '../customHooks/PDFHook';
//material UI
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Button, ListItemSecondaryAction } from '@material-ui/core';
import { QuestionAnswer } from '@material-ui/icons';

//customising button comp

const StyledButton = withStyles({
  root: {
    boxShadow: '6px 6px 0 0 #d1e5fe',
  },
  label: {
    textTransform: '',
  },
})(Button);

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

//importing PDF generating utility functions

const { PDFRequests } = usePDF();

export default function HorizontalLinearStepper() {
  //pulling in vars from context

  const {
    step,
    incrementStep,
    decrementStep,
    resetStep,
    getSteps,
  } = useContext(StepContext);

  const { injectionStep, runUndo, runResponse, resRelationship } = useContext(
    ApiCallContext
  );
  const { outcome, questions } = useContext(InputContext);

  const { PDFObj } = useContext(PDFContext);

  const classes = useStyles();
  const [skipped, setSkipped] = React.useState(new Set());
  const steps = getSteps();

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    console.log(outcome);
    incrementStep();
    let newSkipped = skipped;
    if (isStepSkipped(step)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(step);
    }

    setSkipped(newSkipped);

    if (step === 1) {
      injectionStep();
    }
  };

  const handleBack = () => {
    if (step <= 2) {
      decrementStep();
    }
    //TODO: Change this on a map by map basis
    else if (questions[0].relationship === 'application is for investor') {
      decrementStep();
    } else if (step === 4) {
      //runUndo();
      decrementStep();
    } else {
      runUndo();
    }
  };

  const handleSkip = async () => {
    //call the response function and give it junk to skip
    const responseData = {
      answers: [
        {
          subject: questions[0].subject,
          relationship: resRelationship,
          object: 'none',
          cf: '100',
        },
      ],
    };
    await runResponse(responseData);
    //incrementStep();
  };

  const handleReset = () => {
    resetStep();
    //TODO: reset vars
    window.location.reload();
  };

  const handlePDF = () => {
    //Get data to be placed in PDF
    console.log(PDFObj);
    let formDataCollected = {
      template: '53899e5f-b51e-4acc-8221-60d58d1ed576',
      vars: PDFObj,
    };

    const pdf = new PDFRequests();

    pdf.post('http://demo-pdf.rainbird.ai/_ > hmrc.pdf', formDataCollected);
  };

  return (
    <div className={classes.root}>
      {step === steps.length - 1 ? (
        <div>
          <Button onClick={handleReset} className={classes.button}>
            Reset
          </Button>
          {(function () {
            if (
              outcome === 'EIS' ||
              outcome === 'EIS Knowledge Intensive' ||
              outcome == true
            ) {
              console.log('eis || eis intesnive ');
              return (
                <StyledButton
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    handlePDF();
                  }}
                  className={classes.button}
                >
                  EIS and EIS Knowledge Intensive Form
                </StyledButton>
              );
            } else if (outcome === 'SEIS') {
              console.log('seis');
              return (
                <React.Fragment>
                  <StyledButton
                    variant="contained"
                    color="primary"
                    target="_blank"
                    href="https://public-online.hmrc.gov.uk/lc/content/xfaforms/profiles/forms.html?contentRoot=repository:///Applications/CorporationTax/1.0/VCSSEIS1&template=VCSSEIS1.xdp"
                    className={classes.button}
                  >
                    SEIS Form
                  </StyledButton>
                  <StyledButton
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      handlePDF();
                    }}
                    className={classes.button}
                  >
                    EIS and EIS Knowledge Intensive Form
                  </StyledButton>
                </React.Fragment>
              );
            } else {
              return;
            }
          })()}
        </div>
      ) : (
        <div>
          <div>
            <Button
              disabled={step === 0}
              onClick={handleBack}
              className={classes.button}
            >
              Back
            </Button>
            <React.Fragment>
              {(function () {
                if (
                  step >= 3 &&
                  questions !== undefined &&
                  questions[0].prompt.includes('skip if none')
                ) {
                  return (
                    <Button
                      disabled={step === 0}
                      onClick={handleSkip}
                      className={classes.button}
                    >
                      Skip
                    </Button>
                  );
                }
              })()}
            </React.Fragment>
            <StyledButton
              variant="contained"
              color="primary"
              onClick={handleNext}
              className={classes.button}
              type="submit"
            >
              {step === steps.length - 1 ? 'Finish' : 'Next'}
            </StyledButton>
          </div>
        </div>
      )}
    </div>
  );
}
