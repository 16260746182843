let download = require('downloadjs');

export const usePDF = () => {
  class PDFRequests {
    async post(url, data) {
      fetch('https://demo-pdf.rainbird.ai/_', {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.blob())
        .then((blob) => download(blob, 'hmrc.pdf', 'application/pdf'));
    }
  }

  return { PDFRequests };
};
