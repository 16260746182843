import React from 'react';
import { TextField } from 'formik-material-ui';
import { Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Field } from 'formik';

export default function DateInput(props) {
  //pulling out props
  const { name, id, label, type } = props;
  //custom styles for inputs
  const StyledField = withStyles({
    root: {
      width: '100%',
      justifyContent: 'flex-start',
    },
  })(Field);

  return (
    <Box paddingBottom={4}>
      <StyledField
        component={TextField}
        name={name}
        id={id}
        label={label}
        type={type}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={(e) => console.log(e.target.value)}
      />
    </Box>
  );
}
