import React from 'react';
//Material UI
import { withStyles } from '@material-ui/core/styles';
import { Field } from 'formik';
import { Box } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
//Custom Imports
export default function StyledInput(props) {
  //pulling out props
  const { name, id, label, type, value } = props;

  //custom styles for inputs
  const StyledField = withStyles({
    root: {
      width: '100%',
      justifyContent: 'flex-start',
    },
  })(Field);
  return (
    <React.Fragment>
      <Box paddingBottom={4}>
        <StyledField
          component={TextField}
          name={name}
          id={id}
          label={label}
          type={type}
          value={value}
        />
      </Box>
    </React.Fragment>
  );
}
