import React from 'react';
import { Box } from '@material-ui/core';

export default function LandingPage(props) {
  return (
    <Box lineHeight={2} padding={2}>
      <h2 style={{ marginTop: 0 }}>Venture Capital Schemes</h2>
      <p>
        This tool is designed to asses whether an application is eligible for
        the Venture Capital Scheme, and if so, which one. This is is determined
        by assessing details regarding the investor, the company, their
        relationship, and the investment. During the interaction you will be
        asked for details on these factors in order to determine an
        application’s eligibility so please complete all questions as fully as
        possible.
      </p>
      <p>
        The Venture Capital Scheme allows companies to offer tax incentives to
        investors providing they meet the overall criteria as well as the
        criteria for specific schemes.
      </p>
      <p>The schemes under the Venture Capital Scheme are:</p>
      <ul>
        <li>Seed Enterprise Investment Scheme</li>
        <li>Enterprise Investment Scheme</li>
        <li>Enterprise Investment Scheme Knowledge Intensive</li>
      </ul>
      <p>
        Upon completion of this assessment an outcome regarding which scheme, if
        any, a company may be eligible for along with details of why this result
        has been given will be generated. The interaction will also provide
        part-completed forms for the relevant scheme(s) where applicable or
        further guidance on how to progress your application.
      </p>
      <p>If you have any questions please direct them to: [TBC]</p>
    </Box>
  );
}
