import React, { createContext, useState, useContext } from 'react';
import { ApiCallContext } from './ApiCallContext';
import { InputContext } from './UserInputContext';
//Export data context

export const StepContext = createContext();

const StepContextProvider = (props) => {
  const { outcome, questions } = useContext(InputContext);
  const [step, setStep] = useState(0);

  const incrementStep = (final) => {
    console.log(final);
    //covering edge case that "finish" button makes state> available children
    if (step >= 4) {
      return;
    } else if (questions === undefined) {
      setStep((s) => s + 1);
    } else {
      //covering edge case that back is pushed while question is true
      if (step <= 2) {
        setStep((s) => s + 1);
      }
    }
    //making sure increment happens on final step
    if (
      outcome === 'SEIS' ||
      outcome === 'EIS' ||
      outcome === 'None' ||
      outcome === 'EIS Knowledge Intensive' ||
      outcome === true ||
      outcome === false ||
      final === 'final'
    ) {
      setStep((s) => s + 1);
    }
  };

  const decrementStep = () => {
    //covering edge case that decrement button is pressed on initial state
    if (step <= 0) {
      return;
    } else {
      setStep((s) => s - 1);
    }
  };

  const resetStep = () => {
    setStep(0);
  };

  function getSteps() {
    return [
      'Introduction',
      'Basic Details',
      `Who's Applying?`,
      'Further Questions',
      'Your Tax Summary',
    ];
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return '';
      case 1:
        return 'Basic Details';
      case 2:
        return `Who's Applying?`;
      case 3:
        return 'Further Questions';
      default:
        return 'Your Tax Summary';
    }
  }

  return (
    <StepContext.Provider
      value={{
        step,
        incrementStep,
        decrementStep,
        resetStep,
        getSteps,
        getStepContent,
      }}
    >
      {props.children}
    </StepContext.Provider>
  );
};

export default StepContextProvider;
