import React from 'react';
import { InputContext } from '../Contexts/UserInputContext';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import { TextField, Box } from '@material-ui/core/';

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator
      suffix="%"
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

class MaterialUIInputPercent extends React.Component {
  handleChange = (name) => (event) => {
    this.context.setStandardInput(event.target.value);
  };

  static contextType = InputContext;

  render() {
    const { name, id, label, type, InputProps } = this.props;
    console.log(InputContext.standardInput);
    return (
      <Box paddingBottom={4}>
        <TextField
          style={{ width: '100%', justifyContent: 'flex-start', marginTop: 0 }}
          label={label}
          value={this.context.standardInput}
          onChange={this.handleChange('numberformat')}
          id={id}
          InputProps={{
            inputComponent: NumberFormatCustom,
          }}
        />
        <p style={{ color: 'rgb(118, 118, 118)' }}>{name ? name : null}</p>
      </Box>
    );
  }
}

export default MaterialUIInputPercent;
