import React from 'react';

const styles = {
  display: 'flex',
  justifyContent: 'space-between',
};

export default function RainHead(props) {
  const { image, corporateImage } = props;
  return (
    <React.Fragment>
      {corporateImage
        ? [
            <div style={styles} key={0}>
              <img src={corporateImage} alt="Corporate Branding"></img>
              <img src={image} alt="Powered By Rainbird"></img>
            </div>,
          ]
        : [
            <React.Fragment key={1}>
              <img
                src={image}
                style={{ display: 'inLineBlock' }}
                alt="Powered By Rainbird"
              ></img>
            </React.Fragment>,
          ]}
    </React.Fragment>
  );
}
