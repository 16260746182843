export const useUpdateDOM = () => {
  //States for managing UI

  //Class/methods to manipulate UI
  class updateDom {
    constructor() {
      this.finalResult = [];
      this.questions = [];
      this.response = {};
    }

    processResult(result) {
      if (result.question) {
        this.questions = [];
        this.questions.push(result.question);

        if (result.extraQuestions) {
          result.extraQuestions.forEach(function (extra) {
            this.questions.push(extra);
          });
        }

        if (result.question.type == 'First Form') {
          console.log('first form question');
        } else if (result.question.type == 'Second Form Object') {
          console.log('second form question');
        } else {
          /**
           * TODO: error msg for: "Question type not supported"
           */
          alert('Question type not supported');
        }
      } else if (result.result) {
        this.finalResult.push(result.result);
        if (result.result.length == 0) {
          /**
           * TODO: error msg for: "failed to find an answer"
           */
          alert('failed to find an answer');
        } else {
          /**
           * TODO: display a final result UI
           */
        }
      }
    }
  }

  return { updateDom };
};
