import React, { useContext, useState } from 'react';
//material UI
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
//custom imports
import { InputContext } from '../Contexts/UserInputContext';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  button: {
    display: 'block',
    textTransform: 'none',
  },
  formControl: {
    margin: theme.spacing(1),
    width: '80%',
  },
  listItem: {
    whiteSpace: 'normal',
    maxWidth: '65%',
  },
  select: {},
}));

export default function DropDown(props) {
  const classes = useStyles();
  //pulling in ncontext
  const { setDropDown, menuItem, setMenuItem } = useContext(InputContext);
  //localised state//

  const [open, setOpen] = useState(false);

  const { label, question, menuItems } = props;

  const handleChange = (event) => {
    setMenuItem(event.target.value);
    setDropDown(menuItems[event.target.value]);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div>
      <Typography
        className={classes.button}
        onClick={handleOpen}
        style={{ cursor: 'pointer' }}
      >
        {question}
      </Typography>
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-controlled-open-select-label">{label}</InputLabel>
        <Select
          className={classes.select}
          id="demo-controlled-open-select"
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={menuItem}
          onChange={handleChange}
        >
          {menuItems.map((item, index) => (
            <MenuItem key={index} value={index} className={classes.listItem}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
