import $ from 'jquery';

//custom hook for the YolAPI
export const useYol = () => {
  /**
   * TODO:Rewrite these Jquery requests
   */
  class apiRequests {
    test(hello) {
      return hello;
    }

    isUrl(item) {
      return item && item.indexOf('://') != -1;
    }

    buildParams(urlString, b, c) {
      if (b && b.start_proxy) {
        return {
          url: urlString,
          start_proxy: b.start_proxy,
          kmId: 'supplied-by-proxy',
        };
      } else {
        return { url: urlString, apiKey: b, kmId: c };
      }
    }

    processParameters(a, b, c) {
      if (this.isUrl(a)) {
        return this.buildParams(a, b, c);
      } else if (this.isUrl(b)) {
        return this.buildParams(b, a, c);
      } else if (this.isUrl(c)) {
        return this.buildParams(c, a, b);
      } else {
        return null;
      }
    }

    attemptProxyStart(session, callback) {
      $.ajax({
        type: 'GET',
        url: session.parameters.start_proxy,
        success: function (response, err) {
          if (!err && response) {
            session.id = response.id || response.sessionId;
          }
          callback(err);
        },
      });
    }

    attemptStart(session, allowSwap, callback) {
      var key = session.parameters.apiKey + ':';
      $.ajax({
        type: 'GET',
        url: session.parameters.url + '/start/' + session.parameters.kmId + '',
        beforeSend: function (xhr) {
          xhr.setRequestHeader('Authorization', 'Basic ' + btoa(key));
        },
        success: function (response, err) {
          if (err && err.message === 'Unauthorized') {
            if (allowSwap) {
              var temp = session.parameters.kmId;
              session.parameters.kmId = session.parameters.apiKey;
              session.parameters.apiKey = temp;
              this.attemptStart(session, false, callback);
            } else {
              callback(new Error('Failed to start a session'));
            }
          } else if (response && response.id) {
            session.id = response.id;
            callback(null, response);
          } else {
            callback(err);
          }
        },
      });
    }

    doQuery(session, data, callback) {
      $.ajax({
        type: 'POST',
        url: session.parameters.url + '/' + session.id + '/query',
        contentType: 'application/json',
        data: JSON.stringify(data),
        success: function (response, error) {
          if (error === 'success') {
            error = null;
          }
          callback(error, error ? null : response);
        },
      });
    }

    doMetadata(session, data, callback) {
      $.ajax({
        type: 'POST',
        url: session.parameters.url + '/' + session.id + '/metadata',
        contentType: 'application/json',
        data: JSON.stringify(data),
        success: function (response, error) {
          if (error === 'success') {
            error = null;
          }
          callback(error, error ? null : response);
        },
      });
    }

    doRespond(session, data, callback) {
      let resData = JSON.stringify(data);
      $.ajax({
        type: 'POST',
        url: session.parameters.url + '/' + session.id + '/response',
        contentType: 'application/json',
        data: resData,
        success: function (response, error) {
          if (error === 'success') {
            error = null;
          }
          callback(error, error ? null : response);
        },
      });
    }

    doUndo(session, callback) {
      $.ajax({
        type: 'POST',
        url: session.parameters.url + '/' + session.id + '/undo',
        contentType: 'application/json',
        success: function (response, error) {
          if (error === 'success') {
            error = null;
          }
          callback(error, error ? null : response);
        },
      });
    }

    prepareError(error, response) {
      var result = null;
      if (error) {
        var message = error.message;
        if (response.error) {
          error = response.error;
          message += '\n' + error.message;
          if (error.text) {
            message += '\n' + error.text;
          }
        }
        result = new Error(message);
      }

      return result;
    }

    async doInject(session, arrayOfFacts, callback) {
      /**
       * TODO: rewrite this as async await with fetch... 
       * ? Something like this 
         const settings = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(arrayOfFacts),
      };
      try {
        const fetchResponse = await fetch(
          `${session.parameters.url}/${session.id}/inject`,
          settings
        );
        const data = await fetchResponse.json();
        return data;
        callback(null, data);
      } catch (e) {
        return e;
      }
       */

      $.ajax({
        type: 'POST',
        url: session.parameters.url + '/' + session.id + '/inject',
        contentType: 'application/json',
        data: JSON.stringify(arrayOfFacts),
        success: function (response, error) {
          if (error === 'success') {
            error = null;
          }
          callback(error, error ? null : response);
        },
      });
    }
  }
  function session(a, b, c, d) {
    // creting an instance of the utility class
    let api = new apiRequests();
    this.parameters = api.processParameters(a, b, c);
    var p = this.parameters;
    if (!p || !p.url || (!p.apiKey && !p.start_proxy) || !p.kmId) {
      throw new Error('The Url, apiKey and kmId are all required.');
    }
    this.queryComponents = d || {};
    this.start = function (callback) {
      if (this.parameters.start_proxy) {
        api.attemptProxyStart(this, callback);
      } else {
        api.attemptStart(this, true, callback);
      }
    };
    this.setQueryParam = function (params) {
      this.queryComponents = params;
    };
    this.inject = function (arrayOfFacts, callback) {
      api.doInject(this, arrayOfFacts, callback);
    };
    this.metadata = function (data, callback) {
      api.doMetadata(this, data, callback);
    };
    this.query = function (data, callback) {
      api.doQuery(this, data, callback);
    };
    this.respond = function (data, callback) {
      api.doRespond(this, data, callback);
    };
    this.undo = function (callback) {
      api.doUndo(this, callback);
    };
  }

  //return the logic here

  return { apiRequests, session };
};
