import React, { createContext, useState, useContext, useEffect } from 'react';
import { useYol } from '../../customHooks/yolHook';
import { useUpdateDOM } from '../../customHooks/DOMHook';
import { InputContext } from './UserInputContext';
import { StepContext } from './StepContext';

export const ApiCallContext = createContext();

//Bring in API methods from yolHook/DOMHook

const { updateDom } = useUpdateDOM();

const { session } = useYol();

//inst required for session class
const RBSession = new session(
  'https://enterprise-api.rainbird.ai', //URL
  '959c4f22-520b-4eb8-b462-3f00aaac84e3', // API Key
  'e20f9cfd-3752-4f4a-8937-d2e4fb0cbe52'  // Knowledge Map ID all dataTypes
);
//creating instance of DOM class
const DOM = new updateDom();

const ApiCallContextProvider = (props) => {
  const {
    setStandardInput,
    setInputDate,
    canAddValue,
    setCanAddValue,
    outcome,
    setOutcome,
    questions,
    setQuestions,
  } = useContext(InputContext);

  const { incrementStep } = useContext(StepContext);

  const [outcomeFact, setOutcomeFact] = useState();
  const [evidence, setEvidence] = useState('');
  const [resRelationship, setResRelationship] = useState('');

  const injectionStep = async (values) => {
    /**
     * TODO: Clean Values into what RB expects
     * TODO: Ideally we would like to test/ flag info for injection
     */

    if (values !== undefined) {
      const injectDataName = [
        {
          subject: 'A apllication',
          relationship: 'individual scheme eligibility', //'individual scheme eligibility'
          object: values.Name,
        },
      ];

      RBSession.start(function (err) {
        if (err) throw err;
        //run an injection

        RBSession.inject(injectDataName, function (err, result) {
          if (err) throw err;
          console.log(result);
        });

        // Configure your query here
        var queryData = {
          subject: 'Application 1',
          relationship: 'individual scheme eligibility', //'individual scheme eligibility' 'application is vcseligible'
        };

        /**
         * TODO: Definitely need a rethink on this...
         */

        //initial query/ purpose of interaction
        setTimeout(function () {
          RBSession.query(queryData, function (err, result) {
            if (err) throw err;
            //the result returned will either be a question or an answer to the initial query.
            console.log({ result });
            setResRelationship(result.question.relationship);
            DOM.processResult(result);
            let questions = DOM.questions;
            setQuestions(questions);
          });
        }, 100);
      });
    }
  };

  const runResponse = (response) => {
    console.log({ response });
    RBSession.respond(response, function (err, result) {
      if (err) throw err;
      console.log({ result });
      if (result.question) {
        //reset all dataTypes
        setStandardInput('');
        setInputDate('');
        setCanAddValue(null);
        setResRelationship(result.question.relationship);
        DOM.processResult(result);
        let questions = DOM.questions;
        setQuestions(questions);
      }

      if (result.result) {
        DOM.processResult(result);
        let finalResult = DOM.finalResult;
        setOutcome(finalResult[0][0].object);
        setOutcomeFact(finalResult[0][0].factID);
        //this is undefined
        console.log(outcome);
        //this is fine
        console.log(finalResult[0][0].object);
        incrementStep('final');
      }
    });
  };

  const runUndo = (response) => {
    console.log({ response });
    RBSession.undo(function (err, result) {
      if (err) throw err;
      console.log({ result });
      if (result.question) {
        //reset all dataTypes
        setStandardInput('');
        setInputDate('');
        setCanAddValue(null);
        setResRelationship(result.question.relationship);
        DOM.processResult(result);
        let questions = DOM.questions;
        let questionArray = [];
        //TODO: manipulate all dataTypes to contain correct no of empty objects in their contexts
        /*questions.map((question) => {
          if (question.dataType === 'number') {
            questionArray.push({});
          }
        });
        setStandardInput(questionArray);
        */
        setQuestions(questions);
      }
    });
  };

  return (
    <ApiCallContext.Provider
      value={{
        injectionStep,
        runResponse,
        runUndo,
        resRelationship,
        setResRelationship,
        outcomeFact,
        setOutcomeFact,
        evidence,
        setEvidence,
      }}
    >
      {props.children}
    </ApiCallContext.Provider>
  );
};

export default ApiCallContextProvider;
