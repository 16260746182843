import { CheckboxWithLabel } from 'formik-material-ui';
import React from 'react';
import { Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Field } from 'formik';
import {
  Checkbox,
  FormGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from '@material-ui/core';

export default function DateInput(props) {
  //pulling out props
  const { value, title, label, labelPlacement } = props;

  //TODO: handle data input for this checkbox
  return (
    <Box paddingBottom={4}>
      <FormControl component="fieldset">
        <FormLabel component="legend">{title}</FormLabel>
        <FormGroup aria-label="position" row>
          <FormControlLabel
            value={value}
            control={<Checkbox color="primary" />}
            label={label}
            labelPlacement={labelPlacement}
          />
        </FormGroup>
      </FormControl>
    </Box>
  );
}
