import React, { createContext, useContext } from 'react';
//import { ApiCallContext } from './ApiCallContext';
export const PDFContext = createContext();

const PDFContextProvider = (props) => {
  //bring in outcome var
  //const { outcome } = useContext(ApiCallContext);
  //bringing in variables for all dropdowns etc

  let PDFObj = {
    //initialise all of the values you wish to have in your PDF here with '' as a value
    //Page 1
    //TODO: Checkbox here
    pageOneDateDayInput: '',
    pageOneDateMonthInput: '',
    pageOneDateYearInput: '',
    companyName: '',
    CorpTaxRef: '',
    CRN: '',
    //Page 2
    lineOne: '',
    lineTwo: '',
    lineThree: '',
    postCode: '',
    pageTwoDateDayInput: '',
    pageTwoDateMonthInput: '',
    pageTwoDateYearInput: '',
    companyActivities: '',
    //TODO: Checkbox here
    //TODO: Checkbox here
    contactName: '',
    agent: '',
    telephone: '',
    //TODO: Checkbox here
    //Page 3
    pageThreeDateDayInput: '',
    pageThreeDateMonthInput: '',
    pageThreeDateYearInput: '',
    desOfShares: '',
    valOfShares: '',
    //TODO: Checkbox here
    subs: '',
    subFullName: '',
    subAddress: '',
    sharesIssued: '',
    ammountPaid: '',
    totalShares: '',
    //Page 4
    totalPaid: '',
    //TODO: Checkbox here
    detailsOfTrade: '',
    pageFourDateDayInput: '',
    pageFourDateMonthInput: '',
    pageFourDateYearInput: '',
    //TODO: Checkbox here
    companyRiskCapital: '',
    fullTimeEmployees: '',
    // Gross capital before
    grossBefore1: '',
    grossBefore2: '',
    grossBefore3: '',
    grossBefore4: '',
    grossBefore5: '',
    //Page 5
    //Gross capital after
    grossAfter1: '',
    grossAfter2: '',
    grossAfter3: '',
    grossAfter4: '',
    grossAfter5: '',
    desOfShares: '',
    nomValShares: '',
    totalSharesIssued: '',
    shareholder: '',
    hasSubsidiaries: '',
    //Page 6
    //TODO: Checkbox here
    //TODO: Checkbox here
    //TODO: Checkbox here
    pageFiveDateDayInput: '',
    pageFiveDateMonthInput: '',
    pageFiveDateYearInput: '',
    subName: '',
    nature: '',
    //TODO: Checkbox here
    //TODO: Checkbox here
    //Page 7
    //TODO: Checkbox here
    pageSevenDetails: '',
    //TODO: Checkbox here
    //TODO: Checkbox here
    pageSevenPayments: '',
    totalRiskFinance: '',
    saleWithin7: '',
    //page 8
    saleWithin10: '',
    firstCommercialSale: '',
    //TODO: Checkbox here
    pageNineDateDayInput: '',
    pageNineDateMonthInput: '',
    pageNineDateYearInput: '',
    //TODO: Multi Checkbox here
    //Page 9
    //TODO: Whole page of checkboxes
    trueCheckBox: '',
    //Page 10
    //TODO: Checkbox here
    applicantName: '',
    capacity: '',
    pageTenDateDayInput: '',
    pageTenDateMonthInput: '',
    pageTenDateYearInput: '',
  };
  let saleWithinTenYears;
  //logic for setting values of PDFData//

  const handlePDFData = (values, question) => {
    //handling data from initial inject
    if (question === undefined) {
      console.log(values);
      //TODO: Change date format on front end to match the form
      //The date is one input on the front end but 3 on the form. So, we need to split it.
      PDFObj.companyName = values.Name;
      PDFObj.CRN = values.CN;
      PDFObj.CorpTaxRef = values.CorpTaxRef;
      PDFObj.lineOne = values.AddressLine1;
      PDFObj.lineTwo = values.AddressLine2;
      PDFObj.lineThree = values.AddressLine3;
      PDFObj.postCode = values.PostCode;
      PDFObj.trueCheckBox = values.terms;
      //at the end of each step we should update the useState hook as the interaction could potentially end.
      console.log({ PDFObj });
    } else {
      //switch to handle all subsequent questions
      switch (question.relationship) {
        case "information gathering company's activities":
          PDFObj.companyActivities = values;
          break;
        //TODO: check this item.
        case `information gathering company's qualifying activities`:
          PDFObj.detailsOfTrade = values;
          break;
        case 'application is for investor':
          PDFObj.subFullName = values;
          console.log(PDFObj);
          break;

        case "company's qualifying activities":
          PDFObj.companyActivities = values;
          console.log(PDFObj);
          break;
        case 'risk-to-capital':
          PDFObj.companyRiskCapital = values;
          console.log(PDFObj);
          break;
        case 'relevant first commercial sale':
          //set all three Inputs
          let dateArrayP9 = values.split('-');
          console.log(dateArrayP9);
          PDFObj.pageNineDateDayInput = dateArrayP9[2];
          PDFObj.pageNineDateMonthInput = dateArrayP9[1];
          PDFObj.pageNineDateYearInput = dateArrayP9[0];
          console.log(PDFObj);
          //calculating if date is > 7 years in the past
          const days = 2556.75;
          const todayDate = new Date();
          console.log(values);
          const dateGiven = new Date(values);
          const diff = Math.abs(todayDate - dateGiven);
          const diffInDays = Math.ceil(diff / (1000 * 60 * 60 * 24));
          console.log(diffInDays);
          if (diffInDays <= days) {
            console.log('valid sale date');
            PDFObj.saleWithin7 = true;
          }
          //calculating if the date is within 10 years of first commercial sale
          let tenYearsInDays = 3655;

          if (diffInDays <= tenYearsInDays) {
            saleWithinTenYears = true;
          } else {
            saleWithinTenYears = false;
          }
          break;
        case 'The trade, or the research and development intended to lead to a trade, commenced on:':
          console.log(values);
          let dateArrayp4 = values.split('-');
          console.log(dateArrayp4);
          PDFObj.pageFourDateDayInput = dateArrayp4[2];
          PDFObj.pageFourDateMonthInput = dateArrayp4[1];
          PDFObj.pageFourDateYearInput = dateArrayp4[0];
          console.log(PDFObj);
          break;
        case 'application is for investment':
          PDFObj.totalPaid = values;
          console.log(PDFObj);
          break;
        case 'information gathering risk-to-capital':
          PDFObj.companyRiskCapital = values;
          console.log(PDFObj);
          break;
        case 'gross assets prior to investment':
          if (values <= 1000000) {
            PDFObj.grossBefore1 = true;
          } else if (values >= 1000001 && values <= 5000000) {
            PDFObj.grossBefore2 = true;
          } else if (values >= 5000001 && values <= 10000000) {
            PDFObj.grossBefore3 = true;
          } else if (values >= 10000001 && values <= 15000000) {
            PDFObj.grossBefore4 = true;
          } else {
            PDFObj.grossBefore5 = true;
          }

          console.log(PDFObj);
          break;
        case 'gross assets after investment':
          if (values <= 1000000) {
            PDFObj.grossAfter1 = true;
          } else if (values >= 1000001 && values <= 5000000) {
            PDFObj.grossAfter2 = true;
          } else if (values >= 5000001 && values <= 10000000) {
            PDFObj.grossAfter3 = true;
          } else if (values >= 10000001 && values <= 16000000) {
            PDFObj.grossAfter4 = true;
          } else {
            PDFObj.grossAfter5 = true;
          }
          break;
        case 'investor has relationship to company':
          if (values === 'Shareholder') {
            PDFObj.shareholder = true;
          }
          console.log(PDFObj);
          break;
        case 'company owns subsidiary shares':
          if (values >= 51) {
            PDFObj.hasSubsidiaries = true;
          }
          break;
        case 'relevant first commercial sale':
          PDFObj.firstCommercialSale = true;
          break;
      }
    }

    //handling company being KI and having sale within 10 years

    /*if (outcome === 'SEIS' || 'EIS' || 'EIS Knowledge Intensive') {
      if (saleWithinTenYears) {
        PDFObj.saleWithin10 = true;
      }
    }*/
  };

  return (
    <PDFContext.Provider value={{ PDFObj, handlePDFData }}>
      {props.children}
    </PDFContext.Provider>
  );
};

export default PDFContextProvider;

/**
 * TODO: Date helper function 
 * function createDate(days, months, years) {
        var date = new Date(); 
        date.setDate(date.getDate() + days);
        date.setMonth(date.getMonth() + months);
        date.setFullYear(date.getFullYear() + years);
        return date;    
    }
 */
