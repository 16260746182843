import React, { useContext } from 'react';
import TextField from '@material-ui/core/TextField';
import { Box } from '@material-ui/core';
import { InputContext } from '../Contexts/UserInputContext';

export default function MaterialUIDatePicker(props) {
  const { inputDate, setInputDate } = useContext(InputContext);

  const handleDateChange = async (date) => {
    console.log(date);
    await setInputDate(date);
    // await this.context.setInputDate(this.state.value);
  };

  const { name, id, label, type } = props;

  return (
    <Box paddingBottom={4}>
      <TextField
        style={{ width: 600 }}
        name={name}
        id={id}
        label={label}
        type={type}
        InputLabelProps={{
          shrink: true,
        }}
        value={inputDate}
        onChange={(event) => {
          handleDateChange(event.target.value);
        }}
      />
    </Box>
  );
}
