import React from 'react';
import './App.css';

//Material UI components
import { Container } from '@material-ui/core';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { Box, Card } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

//Custom Components
import RainForm from './components/RainForm';
import RainHead from './components/RainHead';
import RainTitle from './components/RainTitle';
import BreadCrumb from './components/Breadcrumb';

//images for head
import { Helmet } from 'react-helmet';
import RainLogo from './img/RainLogo.png';
import RainLogoSmall from './img/RainLogoSmall.png';
import TSB from './img/tsb.png';
import PBLarge from './img/PBLarge.svg';
//import Context provider for State
import StepContextProvider from './components/Contexts/StepContext';
import ApiCallContextProvider from './components/Contexts/ApiCallContext';
import InputContextProvider from './components/Contexts/UserInputContext';
import PDFContextProvider from './components/Contexts/PDFContext';

//custom theme

const Theme = createMuiTheme({
  palette: {
    primary: {
      light: '#d1e5fe',
      main: '#5a24fb',
      dark: '#2e1648',
      // TSB hex's light: #005c9e; dark #26358c  PB hex: #008080 light: #40c1ac RB main: '#5a24fb' light: '#d1e5fe' dark: #2e1648:
    },
    secondary: {
      main: '#d1e5fe',
    },
  },

  shape: {
    borderRadius: 0,
  },
});

//custom styles for container

const useStyles = makeStyles({
  root: {
    maxWidth: '1070px',
  },
});

function App(props) {
  const classes = useStyles();

  return (
    <ThemeProvider theme={Theme}>
      <InputContextProvider>
        <StepContextProvider>
          <ApiCallContextProvider>
            <PDFContextProvider>
              <Box marginTop={4}>
                <Helmet>
                  <meta charSet="utf-8" />
                  <title>VCS Eligibility Tool</title>
                  <link href="" />
                </Helmet>
                <Container className={classes.root}>
                  <RainHead corporateImage={null} image={RainLogo} />
                  <RainTitle title={null /*'Project Sycamore'*/} />
                  <Card>
                    <BreadCrumb />
                    <RainForm color={Theme.palette.secondary.main} />
                  </Card>
                </Container>
              </Box>
            </PDFContextProvider>
          </ApiCallContextProvider>
        </StepContextProvider>
      </InputContextProvider>
    </ThemeProvider>
  );
}

export default App;
