import React from 'react';
import { Box, InputLabel, makeStyles } from '@material-ui/core';
//custom imports
import StyledInput from './smallAndReusable/FormikInput';
import DateInput from './smallAndReusable/DateInput';
import CheckBox from './smallAndReusable/CheckBox';
import { spacing } from '@material-ui/system';

const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: 'teal',
  },
});

export default function InjectForm() {
  const styles = useStyles();
  return (
    <div>
      <Box paddingBottom={4}>
        <StyledInput name="Name" label="Company Name" />
      </Box>
      <Box paddingBottom={4}>
        <StyledInput name="CN" label="Company Number" />
      </Box>
      <Box paddingBottom={4}>
        <StyledInput name="CorpTaxRef" label="Corporation Tax Reference" />
      </Box>
      <Box paddingBottom={4}>
        <StyledInput name="AddressLine1" label="Address Line 1" />
      </Box>
      <Box paddingBottom={4}>
        <StyledInput name="AddressLine2" label="Address Line 2" />
      </Box>
      <Box paddingBottom={4}>
        <StyledInput name="AddressLine3" label="Address Line 3" />
      </Box>
      <Box paddingBottom={4}>
        <StyledInput name="PostCode" label="Post Code" />
      </Box>
      <Box paddingBottom={4}>
        <DateInput
          name="Date"
          id="date"
          label="Year End"
          type="date"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Box>
      <Box paddingBottom={4}>
        <StyledInput name="Email" label="Email Address" />
      </Box>
      <Box paddingBottom={2}>
        <Box pb={4}>
          <CheckBox
            label="I confirm all information provided is true."
            labelPlacement="end"
          />
        </Box>
      </Box>
    </div>
  );
}
