import React, { useContext } from 'react';
import { TextField, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { InputContext } from '../Contexts/UserInputContext';
import { object, mixed, number, date, string } from 'yup';
import '../ComponentCSS/MaterialUIInput.css';

const useStyles = makeStyles({
  root: {
    width: '100%',
    justifyContent: 'flex-start',
    marginTop: 0,
  },
});

export default function MaterialUIInput(props) {
  const { name, id, label, type, InputProps, questionLength } = props;

  const { setStandardInput, standardInput } = useContext(InputContext);

  const classes = useStyles();

  let styleOveride = null;

  console.log(questionLength);

  if (type === 'number') {
    styleOveride = { width: 600 };
    console.log('number');
  }

  if (questionLength === 'long') {
    styleOveride = { width: 850 };
    console.log('long');
  }

  //handle input change
  const handleChange = (e) => {
    setStandardInput(e.target.value);
    /**
     * TODO: Record data for PDF in a hash table
     */
  };
  return (
    <Box paddingBottom={4}>
      <TextField
        className={classes.root}
        name={name}
        id={id}
        label={label}
        type={type}
        onChange={(e) => {
          handleChange(e);
        }}
        value={standardInput}
        InputProps={InputProps}
        style={styleOveride}
      />
    </Box>
  );
}
